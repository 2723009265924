body, html {
	overflow-y: auto;
}

body.scroll-locked {
	position: fixed;
	left: 0;
	width: 100%;
	height: auto;
	overflow: hidden;
	box-sizing: border-box;
	background-color: transparent;
}

// .ModalContainer-enter {
// 	opacity: 0.01;
// }

// .ModalContainer-enter.ModalContainer-enter-active {
// 	opacity: 1;
// 	transition: opacity 150ms ease-in;
// }

// .ModalContainer-exit {
// 	opacity: 1;
// }

// .ModalContainer-exit.ModalContainer-exit-active {
// 	opacity: 0.01;
// 	transition: opacity 150ms ease-in;
// }

.ModalContainer {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 1000;
	font-size: 0;
	white-space: nowrap;
	overflow: auto;
	text-align: center;
	-webkit-overflow-scrolling: touch;
}
.ModalContainer:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	width: 0;
}
.ModalContainer__overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
}
.ModalContainer__content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	white-space: normal;
	font-size: 16px;
	max-width: 780px;
	//background: #fff;
	padding: 30px;
	//margin: 10px;
	box-sizing: border-box;
}
.ModalContainer__close {
	position: absolute;
	top: 35px;
	right: -5px;
	font-size: 35px;
	font-weight: bold;
	line-height: 20px;
	cursor: pointer;
	background: none;
	border: none;
	padding: 0;
	color:#fff;
	border-radius: 1%;
	
	@media screen and(max-width:1100px) {
		top:-10px;
		right:40px;
		//color:#000;
		
	}
}
.ModalContainer__inner {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
