

@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

//Compteur

// .counter{
//     //background:$color-3;
//     text-align:center;
//     position:relative;
//  }
//  .counter .counter-content{
//     padding:30px 20px 50px;
//     color:white;
//     position:relative;
//  }

// .counter:before,.couter:after,
// .counter .counter-content:before,
// .counter .counter-content:after{
// content:"";
// width:20px;
// height:20px;
// border-radius:50%;
// background:white;
// position:absolute;
// box-shadow:inset02px 2px#000;
// top:15px;
// left:15px;
// }
// .counter:after{
//     left:auto;
//     right:15px;
// }
// .counter .counter-content:before,
// .counter .counter-content:after{
//    box-shadow:inset 0-2px 2px#000;
//     top:auto;
//    bottom:15px;
// }
// .counter .counter-content:after{
//     left:auto;
//    bottom:15px;
//     right:15px;
// }
.module{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 135px;
    margin-top: 150px;
    width: 100%;
    @media screen and(max-width:767px) {
       margin-top: 20px;
       flex-direction: column;
      
        
    }
}
.count{
    font-weight: bold;
    font-size: 35px;
    text-align: center;
    display: block;
    margin: auto;
    // @media screen and (max-width:767px) {
    //     font-size: 17px;
    // }
}
.pcount {
    padding: 20px;
    // word-wrap: break-word;
}


.sectionCount{
    @media screen and (max-width:768px) {
        padding-top:15px;
        
    }
    h4{
        color:$color-3;
        font-weight: bold;
    }
}
.gbblue{
    display: block;
    background:$blue-1;
    height:max-content;
    border-radius: 5px 5px 5px 5px;
    width: max-content;
    text-align: center;
    margin: auto;
    padding: 3px;

    @media screen and (max-width:767px) {
        width: 70%;
    }
.button_text{
    padding: 15px 10px;
    text-align: center;
    display: block;
    margin:auto;
    @media screen and (max-width:767px) {
        font-size: 12px;
    }
 
}
}