@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.bfrimage {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width:767px) {
        flex-direction: column;
    }

    .card {
        position: relative;
        text-align: center;
        overflow: hidden;
        width: 600px;
        height: 700px;
        background-color: #eee;
        border-radius: 10px;
        outline: 2px solid #fff;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, .1);
        outline-offset: -8px;
        margin-top: 15px;

        @media screen and (max-width:767px) {
            width: 300px;
            height: 350px;

        }

        @media screen and (max-width:1100px) {
            margin-top: 0;

        }
    }

    .card-image {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
         object-fit:cover;
        opacity: .6;
        transition: transform .75s;
        @media screen and (max-width:767px) {
            width: 300px;
            height: 350px;

        }
    }

    .img1 {

        object-fit: cover;
        inset: -1;
       
        

    }

    .card:hover .card-image {
        transform: scale(1.15);
        opacity: 1;
    }

    .card-title,
    .card-title1,
    .card-title2 {
        position: absolute;
        bottom: 50px;
        left: 8px;
        text-shadow: 2px 3px 4px rgba(0, 0, 0, .5);
        writing-mode: vertical-lr;
        text-orientation: mixed;
        font-weight: bold;
        color: #FFFAFA;
        font-size: 35px;
        padding: 0;
        margin: 0 2px;
        text-transform: uppercase;
        transform: rotate(-180deg);

        @media screen and (max-width:768px) {
            font-size: 20px;

        }
    }

    .card-title1 {

        left: 8px;
    }

}

.testimonials {
    background: url("https://mad-up.com/home/pattern-bg-2.jpg");
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 80px 0;

    .title {

        font-weight: 900;
        color: #3e3e3e;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;
    }

    .react-multi-carousel-list {
        margin: 70px auto;

    }
}

.clients {
    background: #a4c1cc;
    padding: 15px 0;
    // margin-bottom: 150px;

    .title {

        font-weight: 700;
        color: $color-blue;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;
    }

    p {
        font-size: 18px;
        color: #383838;
        text-align: center;
        padding: 0 15px;
        font-weight: 500;
    }

    .button {
        display: flex;
        margin: 0 auto;
        width: fit-content;
    }

    .react-multi-carousel-list {
        margin: 5px auto;
        padding: 0px 15px 30px;
    }
    @media screen and (max-width:1100px) {
        .react-multiple-carousel__arrow--right, .react-multiple-carousel__arrow--left {
            display: none;
        }
    }
    
}


.margeAbout {
    margin-top: 0px;
}

.about-us_title {
    h1{
        margin-top:55px;
    }
    h2 {
        span {
            display: block;
            
        }
    }
}

.about-us_title {
    h2 {
        span {
            display: block;
        }
    }
}


//map
.media {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    //flex-direction: row-reverse;
    @media screen and (max-width:1100px) {
        flex-direction: column;
    }
}

.media .content {
    flex: 1;
    margin: auto;
    padding-left: 13px;
}

.image {
    flex: 1;
    position: relative;

}

.image img {
    max-width: 100%;
}




//animation point

.tooltip_circle,
.tooltip_circle1,
.tooltip_circle3,
.tooltip_circle4,
.tooltip_circle5,
.tooltip_circle6,
.tooltip_circle7,
.tooltip_circle8,
.tooltip_circle9,
.tooltip_circle10,
.tooltip_circle11,
.tooltip_circle12,
.tooltip_circle13,
.tooltip_circle15,
.tooltip_circle16,
.tooltip_circle17 {
    position: absolute;
    background: $color-3;
    z-index: 1;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width:767px) {
        width: 8px;
        height: 8px;
    }
    

}
.tooltip_circle, .tooltip_circle2,.tooltip_circle4,.tooltip_circle6,.tooltip_circle8,.tooltip_circle10,.tooltip_circle13,.tooltip_circle15,.tooltip_circle17{
    animation: transition 3s linear 0.5s forwards infinite;
}
.tooltip_circle1, .tooltip_circle3,.tooltip_circle5,.tooltip_circle7,.tooltip_circle9,.tooltip_circle11,.tooltip_circle12,.tooltip_circle14,.tooltip_circle16{
    animation: transition2 3s linear 0.5s forwards infinite;
    opacity: 0;
}
//Amerique
.tooltip_circle {
    
    top: 40%;
    left: 19%;
   
}

.tooltip_circle1 {

    top: 47%;
    left: 23%;
}
.tooltip_circle13 {

    top: 42%;
    left: 27%;
   
}




//europe
.tooltip_circle4 {

    top: 37%;
    left: 48%;

}

 .tooltip_circle5 {
    top: 39%;
    left: 47%;
  
}

.tooltip_circle6 {
    top: 41.5%;
    left: 46%;
   
}

.tooltip_circle7 {
    top: 36%;
    left: 50%;
    
}

.tooltip_circle8 {
    top: 38%;
    left: 50%;
    
}

.tooltip_circle9 {
    top: 41%;
    left: 51%;
    
}

.tooltip_circle10 {
    top: 37%;
    left: 52%;
}

.tooltip_circle14 {

    top: 41%;
    left: 53%;
    
}

.tooltip_circle17 {
  
    top: 41%;
    left: 56%;
   
}
.tooltip_circle12 {
    top: 26.5%;
    left: 53%;
   

}
.tooltip_circle3 {
    top: 34%;
    left: 47%;
}
//afrique
.tooltip_circle11 {

    top: 44%;
    left: 47%;
}

//Assie
.tooltip_circle15 {
   
    top: 28%;
    left: 70%;
   
}

.tooltip_circle16 {
    top: 45%;  
    left: 68%;
    
}

@keyframes transition {
    0% {
       
        opacity: 0;
    }
    50% {
        
        opacity: 0.9;
    }
    100% {
        
        opacity: 1;
    }
}

@keyframes transition2 {
    0% {
      
        opacity: 1;
    }
    50% {
        
        opacity: 0.9;
    }
    100% {
       
        opacity: 0;
    }
}

//Partenaire
.margePartenaire {
    margin-top: 35px;
}



.size {
    font-size: 50px;
}

.tooltips-home {
    background: $grey-3;
    padding-top: 10px;

    .tooltips-grid {
        display: grid;
        grid-template-columns: 60% 1fr;
        align-items: center;

        .tools_list {
            left: -10%;
            position: relative;
        }
    }

    @media (max-width:768px) {
        .tooltips-grid {
            grid-template-columns: 1fr;

            .tools_list {
                left: 5%;
            }
        }


    }
}

.client-slider {
    .react-multi-carousel-track {
        margin-left: -20px;
    }

    .react-multi-carousel-dot-list {
        bottom: -18px;
        transform: scale(0.8);

        button {
            width: 15px;
            height: 15px;
        }
    }

    &>div {
        display: grid;
        justify-self: center;
    }

    ul {
        li {
            &>div {
                width: fit-content;
                margin: 0 auto;
                cursor: pointer;

                // img {
                //     filter: grayscale(100%);
                //     @include transition;
                // }

                // &:hover {
                //     img {
                //         filter: grayscale(0%);
                //     }
                // }

            }

            // &:nth-child(1) {
            //     img {
                   
            //         padding-top: 12px;
            //         //background-color: #fff;
            //         //border-radius: 50%;
            //     }
            // }
            
        }
    }
}

.testimonal-slider {
    overflow: visible !important;

    .react-multi-carousel-dot-list {
        bottom: -50px;

        button {
            width: 20px;
            height: 20px;
        }
    }

    // &:after {
    //     content: "";
    //     height: 100%;
    //     width: 500px;
    //     position: absolute;
    //     top: 0;
    //     left: -300px;
    //     background: linear-gradient(to right, #fff, #ffffff00);
    //     z-index: 2;
    // }

    // &:before {
    //     content: "";
    //     height: 100%;
    //     width: 500px;
    //     position: absolute;
    //     top: 0;
    //     right: -300px;
    //     background: linear-gradient(to left, #fff, #ffffff00);
    //     z-index: 2;
    // }


}


// .scroll-container {
//     display: block;
//     overflow-y: scroll;
//     scroll-behavior: smooth;
// }