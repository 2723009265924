@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";


/* css list with numeber circle background -------------- */
// @media (min-width:998px){
//     .tooltips-flex{
//         display: flex;
//         align-items: center;
//         justify-content: space-around;
//     }
// }


.tools {
    margin-top: 50px;

    width: 100%;
    background-repeat: no-repeat;
    position: relative;

    h2 {
        text-align: left;
    }

    .imgBonhomme {
        //max-width: 80%;
        width: 48%;
        display: block;
        margin: auto;
        padding: 20px;
        max-height: 80%;
        height: 80%;

        @media screen and (max-width:1100px) {
            width: 100%;
            height: 100%;

        }
    }

    .card-titleBonhomme {
        position: absolute;
        bottom: 65px;
        left: 75px;
        text-shadow: 2px 3px 4px rgba(0, 0, 0, .5);
        writing-mode: vertical-lr;
        text-orientation: mixed;
        font-weight: bold;
        color: #FFFAFA;
        font-size: 30px;
        padding: 0;
        margin: 0 2px;
        text-transform: uppercase;
        transform: rotate(-180deg);

        @media screen and (max-width:767px) {
            // font-size:15px;
            // left:30px;
            display: none;

        }
        @media screen and(min-width:1100px) and (max-width:1200px){
            font-size: 25px;
        }
    }

    .imgBloc {
        width: 100%;
        //height: 100%;
        display: block;
        margin-bottom: 6px;
        //padding: 5px;
        // margin:10px;
    }

    h3 {
        color: #15acf2;
        font-weight: bold;

    }

    a {
        color: #15acf2;
        background-color: transparent;
    }

    .tool {

        position: absolute;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-5;
        border-radius: 50%;
        font-weight: 500;
        cursor: pointer;

        @media (max-width:768px) {
            width: 25px;
            height: 25px;
            font-size: 12px;
        }


        &:nth-child(1) {}
        //epaule
        &:nth-child(3) {
            right: 51%;
            bottom: 28%;

            @media screen and (min-width:1599px) {
                right: 51.5%;
                

            }

        }
        //genou
        &:nth-child(2) {
            right: 45%;
            bottom: 78%;

            @media (max-width:1100px) {
                right: 42%;
                bottom: 74%;
            }

            @media screen and (min-width:1599px) {
                right: 44%;
                bottom: 81%;
            }
        }
        //poignet
        &:nth-child(7) {
            left: 65%;
            top: 22%;


            @media screen and (min-width:1599px) {
                left: 65%;
                top: 22%;

            }
        }
        //pied/cheville
        &:nth-child(6) {
            bottom: 13%;
            right: 50.5%;

            @media screen and (max-width:1100px) {
                right: 42%;
            }

            @media screen and (max-width:768px) {
                right: 43%;

            }

            @media screen and (min-width:1599px) {
                right: 51.5%;
                bottom: 9.5%;

            }


        }
        //hanche
        &:nth-child(5) {
            top: 44%;
            right: 52%;
            
            // .tooltip_block {
            //     left: 28vh !important;
            //     bottom: 25vh !important;
            // }

            @media (max-width:1200px) {
                right: 51%;
            }

        }
        //coude
        &:nth-child(4) {
            top: 21%;
            left: 59%;

            @media (max-width:1100px) {
                left: 35.6%;
            }
        }
        //main
        &:nth-child(8) {
            top: 22%;
            left: 68%;

            @media (max-width:1100px) {
                left: 35.6%;
            }
        }
        //pied
        &:nth-child(9) {
            left: 47%;
            top: 90%;
            // @media screen and(max-width:1100px) {
            //     left:32%;

            // }
            @media screen and(min-width:1100px) and (max-width:1200px){
                top:88%;
                left:46%;
            }
            // @media screen and (max-width:768px) {
            //     left:32%;
            // }
            // @media screen and (min-width:1599px) {
            //     left:50%;
            //     top:20%;

            // }
        }
    }



    .tooltip_circle {
        background: $color-2;
        z-index: 2;
        width: 80%;
        height: 80%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width:1100px) {
            display: none;
        }

    }

    //pour positionner les blocs
    // epaule
    .tooltip_block,
    .tooltip_block2,
    .tooltip_block3,
    .tooltip_block4,
    .tooltip_block5,
    .tooltip_block6,
    .tooltip_block7,
    .tooltip_block8 {
        z-index: 100;
        display: block;
        position: absolute;
        left: 119vh;
        bottom: calc(100% + 200px);
        background: #fff;
        text-align: left;
        padding: 21px 24px;
        color: #000;
        font-size: 25px;
        border-radius: 0px 25px 25px 25px;
        width: 260px;
        max-width: 260px;
        height: max-content;
        // @media screen and(min-width:1100px) and (max-width:1400px){
        //     //transform: translateX(-100%);
        //     bottom: calc(100% + 100px);
        //     left:85vh;
           
        // }
        // @media screen and (min-width:1200px) and (max-width:1399px){
        //     //transform: translateX(-100%);
        //     bottom: calc(100% +80px);
        //     left:85vh;
        //     width: 250px;
        // }
        @media screen and (min-width:1400px) and (max-width:1439px) {
            // resolution entre 1400px et 1440px
            left: 85vh;
            bottom: calc(100% + 120px);
        }
        @media screen and (min-width:1440px) and (max-width:1600x) {
            //resolution entre 1440px et 1600px
            left: 105vh;
            bottom: 25vh;
        }
        // @media screen and (min-width:1600px) and (max-width:1679px) {
        //     // resolution 1600px
        //     left: 85vh;
        //     bottom: calc(100% + 230px);
        // }
        @media screen and (min-width:1680px) and (max-width:1920px) {
            // MAC SCREEN
            left: 100vh;
            bottom: calc(100% + 250px);
        }

        @media screen and (min-width:1920px) {
            // LARGE SCREEN
            left: 110vh;
            bottom: calc(100% + 450px);
        }
       
        @media screen and (max-width:767px) {
            //mobile
            position: fixed;
            left: 29vh;
            top: -40vh;
            border-radius: 25px 25px 25px 25px;
            transform: translate(-50%, -50%);
            width: 165px;
        }
       
        @media screen and (min-width:539px) and (max-width:540px) {
            left: 40vh;
            top:-48vh;
        }
        //TABLETTE
        // @media screen and (max-width:1024px) {
        //     position: fixed;
        //     left: 23vh;
        //     top: -65vh;
        //     border-radius: 25px 25px 25px 25px;
           
        // }
        @media screen and (min-width:768px) and (max-width:1100px) {
            position: fixed;
            left: 24vh;
            top: -55vh;
            border-radius: 25px 25px 25px 25px;
           
        }
    }

    .tooltip_block2 {
        // genou
        left: 45vh;
        bottom: 25vh;
        border-radius: 25px 0 25px 25px;
      
        // @media screen and(min-width:1100px) and (max-width:1400px){
        //     //transform: translateX(-100%);
        //     bottom: calc(100% + 70px);
        //     left:30vh;
           
        // }
        @media screen and (min-width:1400px) and (max-width:1439px){
            left: 32vh;
            bottom: calc(100% + 120px);
        }
        // @media screen and (min-width:1440px) and (max-width:1599px) {
        //     left: 40vh;
        //     bottom: 15vh;
        // }
        // @media screen and (min-width:1600px) and (max-width:1679px) {
        //     // résolution 1600
        //     left: 40vh;
        //     bottom: calc(100% + 145px);
        // }
        
        @media screen and (min-width:1680px) and (max-width:1920px) {
            // MAC SCREEN (résolution:1680)
            left: 48vh;
            bottom: calc(100% + 150px);
        }

        @media screen and (min-width:1920px) {
            // LARGE SCREEN
            left: 60vh;
            bottom: 35vh;
            border-radius: 25px 25px 0 25px;


        }
        
        @media only screen and (max-width:767px) {
            //mobile
            position: fixed;
            left: 50%;
            top: -75vh;
            border-radius: 25px 25px 25px 25px;
            transform: translate(-50%, 50%);
        }
        @media screen and (min-width:539px) and (max-width:540px) {
            //resolution 540*690
            left: 40vh;
            top:-85vh;
        }
        //TABLETTE
        @media screen and (min-width:768px)and(max-width:1100px) {
            position: fixed;
            left: 24vh;
            top: -55vh;
            border-radius: 25px 25px 25px 25px;
            
        }
    }

    .tooltip_block3 {
        // coude
        left: 119vh;
         bottom: 25vh;
        border-radius: 0 25px 25px 25px;
        // @media screen and(min-width:1100px) and (max-width:1400px){
        //     //transform: translateX(-100%);
        //     bottom: 15vh;
        //     left:85vh;
        //     // width: 250px;
        // }
        // @media screen and (min-width:1200px) and (max-width:1399px){
        //     left:85vh;
        //     bottom:20vh;
        // }
        @media screen and (min-width:1400px) and (max-width:1439px) {
            // resolution 1400
            left: 85vh;
            bottom: 20vh;
        }
        // @media screen and (min-width:1440px) and (max-width:1599px) {
        //     left: 105vh;
        //     bottom: 20vh;
        // }
        // @media screen and (min-width:1600px) and (max-width:1679px) {
        //     left: 85vh;
        //     bottom: 20vh;
        // }
        
        @media screen and (min-width:1680px) and (max-width:1920px) {
            // MAC SCREEN
            left: 100vh;
            bottom: 30vh;
        }

        @media screen and (min-width:1920px) {
            // LARGE SCREEN
            left: 107vh;
            bottom: 37vh;
        }
        @media screen and (max-width:767px) {
            //mobile
            position: fixed;
            left: 50%;
            top: -75vh;
            border-radius: 25px 25px 25px 25px;
            transform: translate(-50%, 50%);
        }
        @media screen and (min-width:539px) and (max-width:540px) {
            //resolution 540*690
            left: 40vh;
            top:-85vh;
        }
        //TABLETTE
        @media screen and (min-width:768px)and(max-width:1100px) {
            position: fixed;
            left: 24vh;
            top: -55vh;
            border-radius: 25px 25px 25px 25px;
            
        }
    }

    .tooltip_block4 {
        // hanche
        left: 45vh;
        bottom: 25vh;
        border-radius: 25px 0 25px 25px;
        // @media screen and(min-width:1100px) and (max-width:1400px){
        //     //transform: translateX(-100%);
        //     bottom: 15vh;
        //     left:30vh;
        // }
        @media screen and (min-width:1400px) and (max-width:1439px){
            left: 32vh;
            bottom: calc(100% + 120px);
        }
        // @media screen and (min-width:1440px) and (max-width:1599px) {
        //     left: 40vh;
        //     bottom: 20vh;
        // }
        // @media screen and (min-width:1600px) and (max-width:1679px) {
        //     // MAC SCREEN
        //     left: 40vh;
        //     bottom: calc(100% + 150px);
        // }

        @media screen and (min-width:1680px) and (max-width:1920px) {
            // MAC SCREEN
            left: 48vh;
            bottom: calc(100% + 150px);
        }

        @media screen and (min-width:1920px) {
            // LARGE SCREEN
            left: 60vh;
            bottom: 33vh;
        }
        @media screen and (max-width:767px) {
            //mobile
            position: fixed;
            left: 50%;
            top: -75vh;
            border-radius: 25px 25px 25px 25px;
            transform: translate(-50%, 50%);
        }
        @media screen and (min-width:539px) and (max-width:540px) {
            //resolution 540*690
            top:-85vh;
        }
         //TABLETTE
         @media screen and (min-width:768px)and(max-width:1100px) {
            position: fixed;
            left: 24vh;
            top: -55vh;
            border-radius: 25px 25px 25px 25px;
            
        }

    }

    .tooltip_block5 {
        // poignet/Main
        left: 119vh;
        bottom: 25vh;
        border-radius: 0 25px 25px 25px;
        // width:240px;
        // @media screen and(min-width:1100px) and (max-width:1400px){
        //     //transform: translateX(-100%);
        //     bottom: 13vh;
        //     left:90vh;
            
        // }
        // @media screen and (min-width:1200px) and (max-width:1399px){
        //     left:85vh;
        //     bottom:13vh;
        // }
        @media screen and (min-width:1400px) and (max-width:1439px) {
            // resolution 1400
            left: 85vh;
            bottom: 17vh;
        }
        // @media screen and (min-width:1440px) and (max-width:1599px) {
        //     left: 105vh;
        //     bottom: 22vh;
           
        // }
        // @media screen and (min-width:1600px) and (max-width:1679px) {
        //     // resolution 1600px
        //     left: 85vh;
        //     bottom: 20vh;
        //     // width:320px;
        //     // img{
        //     //     width: 240px;
        //     //     display: block;
        //     //     margin:auto;
        //     //     padding-bottom: 5px;
        //     // }
        // }
        @media screen and (min-width:1680px) and (max-width:1920px) {
            // MAC SCREEN
            left: 99vh;
            bottom: 28vh;
            // width:320px;
        }

        @media screen and (min-width:1920px) {
            // LARGE SCREEN
            left: 107vh;
            bottom: 35vh;
            width:320px;
            border-radius: 25px 0 25px 25px;
        }
        
        @media screen and (max-width:767px) {
            //mobile
            position: fixed;
            left: 50%;
            top: -75vh;
            border-radius: 25px 25px 25px 25px;
            transform: translate(-50%, 50%);
            width: 165px;
        }
        @media screen and (min-width:539px) and (max-width:540px) {
            //resolution 540*690
            top:-85vh;
        }
         //TABLETTE
         @media screen and (min-width:768px)and(max-width:1100px) {
            position: fixed;
            left: 24vh;
            top: -55vh;
            border-radius: 25px 25px 25px 25px;
            
        }
        
    }

    .tooltip_block6 {
        // cheville/Pied
        left: 45vh;
        bottom: 25vh;
        border-radius: 25px 25px 0 25px;
        // @media screen and(min-width:1100px) and (max-width:1400px){
        //     left:30vh;
        //     bottom:15vh;
        // }
        @media screen and (min-width:1400px) and (max-width:1439px){
            left: 32vh;
            bottom: calc(100% + 120px);
        }
        // @media screen and (min-width:1440px) and (max-width:1599px) {
        //     left: 40vh;
        //     bottom: 15vh;
        // }
        // @media screen and (min-width:1600px) and (max-width:1678px) {
            
        //     left: 40vh;
        //     bottom: 20vh;
        // }
        @media screen and (min-width:1680px) and (max-width:1919px) {
            
            left: 48vh;
            bottom: 20vh;
        }

        @media screen and (min-width:1920px) {
            // LARGE SCREEN
            left: 60vh;
            bottom: 15vh;
        }
        @media screen and (max-width:767px) {
            //mobile
            position: fixed;
            left: 50%;
            top: -77vh;
            border-radius: 25px 25px 25px 25px;
            transform: translate(-50%, 50%);
        }
        @media screen and (min-width:539px) and (max-width:540px) {
            //resolution 540*690
            
            top:-85vh;
        }
         //TABLETTE
         @media screen and (min-width:768px)and(max-width:1100px) {
            position: fixed;
            left: 24vh;
            top: -55vh;
            border-radius: 25px 25px 25px 25px;
            
        }

    }

    // .tooltip_block7 {
    //     // main
    //     left: 119vh;
    //     bottom: 25vh;
    //     border-radius: 25px 0 25px 25px;
    //     width:250px;
    //     @media screen and(min-width:1100px) and (max-width:1200px){
    //         left:90vh;
    //         bottom:15vh;
    //         border-radius: 0 25px 25px 25px;
    //     }
    //     // @media screen and (min-width:1200px) and (max-width:1399px){
    //     //     left:85vh;
    //     //     bottom:18vh;
    //     // }
    //     @media screen and (min-width:1400px) and (max-width:1439px) {
    //         // resolution 1400
    //         left: 85vh;
    //         bottom: 15vh;
    //         width:320px;
    //     }
    //     @media screen and (min-width:1440px) and (max-width:1599px) {
    //         left: 105vh;
    //         bottom: 17vh;
    //         width:320px;
    //     }
    //     @media screen and (min-width:1600px) and (max-width:1679px) {
    //         //resolution 1600px
    //         left: 115vh;
    //         bottom: 24vh;
    //         width:320px;
    //     }
    //     @media screen and (min-width:1680px) and (max-width:1920px) {
    //         // MAC SCREEN
    //         left: 99vh;
    //         bottom: 23vh;
    //         width:320px;
    //     }

    //     @media screen and (min-width:1920px) {
    //         // LARGE SCREEN
    //         bottom: 40vh;
    //         left: 107vh;
    //         width:320px;
    //     }
        
    //     @media screen and (max-width:767px) {
    //         //mobile
    //         position: fixed;
    //         left: 50%;
    //         top: -75vh;
    //         border-radius: 25px 25px 25px 25px;
    //         transform: translate(-50%, 50%);
    //         width:165px;
    //     }
    //     @media screen and (min-width:539px) and (max-width:540px) {
    //         //resolution 540*690
            
    //         top:-85vh;
    //     }
    //      //TABLETTE
    //      @media screen and (min-width:768px)and(max-width:1100px) {
    //         position: fixed;
    //         left: 20vh;
    //         top: -55vh;
    //         border-radius: 25px 25px 25px 25px;
            
    //     }
    // }

    // .tooltip_block8 {
    //     // pied
    //     left: 35vh;
    //     border-radius: 25px 25px 0 25px;
    //     border-radius: 25px 0 25px 25px;
    //     @media screen and(min-width:1100px) and (max-width:1200px)
    //     {
    //         left:30vh;
    //     }
    //     @media screen and (min-width:1400px) and (max-width:1439px) {
    //         // resolution 1400
    //         left: 28vh;
    //         bottom: 15vh;
    //     }
    //     @media screen and (min-width:1440px) and (max-width:1599px) {
    //         left: 35vh;
    //         bottom: 15vh;
    //     }
    //     @media screen and (min-width:1600px) and (max-width:1679px) {
    //         // resolution 1600px
    //         left: 43vh;
    //         bottom: 23vh;
    //     }
    //     @media screen and (min-width:1680px) and (max-width:1920px) {
    //         // MAC SCREEN
    //         left: 43vh;
    //         bottom: 15vh;
    //     }

    //     @media screen and (min-width:1920px) {
    //         // LARGE SCREEN
    //         left: 60vh;
    //         bottom: 15vh;
    //         border-radius: 25px 25px 0 25px;
    //     }
    //     @media screen and (max-width:767px) {
    //         //mobile
    //         position: fixed;
    //         left: 50%;
    //         top: -82vh;
    //         border-radius: 25px 25px 25px 25px;
    //         transform: translate(-50%, 50%);
    //     }
    //     @media screen and (min-width:539px) and (max-width:540px) {
    //         //resolution 540*690
            
    //         top:-90vh;
    //     }
    //      //TABLETTE
    //      @media screen and (min-width:768px)and(max-width:1100px) {
    //         position: fixed;
    //         left: 20vh;
    //         top: -55vh;
    //         border-radius: 25px 25px 25px 25px;
            
    //     }
    // }
}

//animation cercle
.waves {
    background: rgba(255, 255, 255, .3);
    width: 25px;
    height: 25px;

    border-radius: 50%;
    -webkit-backface-visibility: hidden;
}

@mixin wave() {
    position: absolute;
    background: #fff;

    width: 50px;
    height: 50px;
    content: "";
    display: block;
    border-radius: 50%;
    -webkit-backface-visibility: hidden;

    @media (max-width:768px) {
        width: 30px;
        height: 30px;
    }
}

.waves:before {
    @include wave();
    animation: wave-animate 3s infinite ease-out;
}

.waves:after {
    @include wave();
    opacity: 0;
    animation: wave-animate 3s 1.5s infinite ease-out;
}

@keyframes wave-animate {
    0% {
        transform: scale(0);
        opacity: 1;
        transform-origin: center;
    }

    100% {
        transform: scale(2);
        opacity: 0;
        transform-origin: center;
    }


}

//pour fixer le scroll
.layer-content {
    display: block;
    position: relative;
    overflow: hidden;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    margin: auto;
    height: 110vh;
    //padding-bottom: 1rem;
    // padding-top: 1rem;
    width: 100%;

    @media screen and(min-width:768px)and(max-width:1100px)  {
        height: 78vh;
       
        
    }
    @media screen and(min-width:1100px) and (max-width:1400px){
        height: 95vh;
       
    }
    // @media screen and (min-width:1600px) and (max-width:1679px) {
    //     // MAC SCREEN
    //     height: 90vh;
      
    // }
    @media screen and (min-width:1680px) and (max-width:1920px) {
        // MAC SCREEN
        height: 100vh;
       
    }

    @media screen and (min-width:1920px) {
        // LARGE SCREEN
        height: 100vh;
        
    }
}

@media screen and (max-width:767px) {
    //mobile
    .layer-content-mobile {
        display: block;
        position: relative;
        overflow: hidden;
        margin: auto;
        height: 90vh;
      
        //padding-bottom: 1rem;
        // padding-top: 1rem;
        width: 100%;

    }
}
@media screen and (min-width:539px) and (max-width:540px) {
    //resolution 540*690
    .layer-content-mobile {
        display: block;
        position: relative;
        overflow: hidden;
        margin: auto;
        height: 110vh;
       // background-color: #000;
      
        //padding-bottom: 1rem;
        // padding-top: 1rem;
        width: 100%;

    }
}


//bacground image
.bg-blue {
    background-color: #a4c1cc;
    border-radius: 20px 20px 20px 20px;
    width: 100%;
    //padding-top: 1rem;
}

//step nav
.navmenu {
    position: absolute;
    top: 30%;
    left: 95%;
    width: 1%;
    height: 40%;


}

.circlestep span {
    display: inline-block;
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background-color: $grey-4;
}

.hr {
    border: 1px solid #000;
    height: 73%;
    display: inline-block;
    position: absolute;

    @media screen and (max-width:767px) {
        left: 45%;
        margin-top: 45%;
    }

    @media screen and(min-width:768px) and (max-width:1170px) {
        left: 45%;
        margin-top: 12px;
    }

    @media screen and(min-width:1170px) and (max-width:1700px) {
        left: 45%;
        top: 5px;
    }

    @media screen and(min-width:1700px) {
        left: 45%;
        top: 0px;
    }
}

.circlestep-01 {
    @media screen and (max-width:800px) {
        margin-top: -36%;
    }

    @media screen and(min-width:800px) and (max-width:850px) {
        margin-top: -18%;
    }

    @media screen and(min-width:850px) and (max-width:900px) {
        margin-top: -5%;
    }

    @media screen and(min-width:900px) and(max-width:940px) {
        margin-top: 10%;
    }

    @media screen and(min-width:940px) and(max-width:990px) {
        margin-top: 25%;
    }

    @media screen and(min-width:990px) and(max-width:1050px) {
        margin-top: 40%;
    }

    @media screen and(min-width:1050px) and(max-width:1120px) {
        margin-top: 53%;
    }

    @media screen and(min-width:1120px) and(max-width:1250px) {
        margin-top: 67%;
    }

    @media screen and(min-width:1250px) and(max-width:1340px) {
        margin-top: 82%;
    }

    @media screen and(min-width:1340px) and(max-width:1440px) {
        margin-top: 95%;
    }

    @media screen and(min-width:1440px) and(max-width:1570px) {
        margin-top: 110%;
    }

    @media screen and(min-width:1570px) and(max-width:1700px) {
        margin-top: 123%;
    }

    @media screen and(min-width:1700px) {
        margin-top: 135%;
    }

    .button {
        display: inline-flex;
        align-items: center;
        color: rgb(6, 15, 30);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        height: 50px;
        min-width: 120px;
        position: relative;
        padding: 0px 20px;
        max-width: fit-content;
        &_text {
            color: rgb(6, 15, 30);
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0px;
            z-index: 2;
            text-transform: uppercase;
        }
    
        &:disabled {
            opacity: 0.5;
        }
    
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 72px;
            background-color: rgb(252, 242, 43);
            border-radius: 40px;
            transition: all 0.3s ease-out 0s;
        }
    
        .hyphen {
            width: 20px;
            height: 2px;
            margin-right: 9px;
            background-color: rgb(6, 15, 30);
            transform-origin: 50% 50%;
            z-index: 1;
        }
    
        &:hover {
            &:before {
                width: 100%;
            }
    
            .hyphen {
                animation: 1s linear 0s 1 normal none running kMPRhr;
            }
        }
    
    
        &_grey {
            color: $color-5; 
            .button_text {
                color: $color-5; 
            }
            &:before {
                background-color:$button_grey;  
            }
            .hyphen {
                background-color:$color-5; 
            }
            &:hover {
                &:before {
                    background-color:$color-3;  
                } 
            }
        }
    
        &_light {
            color: $color-5; 
            .button_text {
                color: #383838; 
            }
            &:before {
                background-color:#e2e2e2;  
            }
            .hyphen {
                background-color:$color-5; 
            }
            &:hover {
                .button_text {
                    color: $color-5 !important; 
                }
                .hyphen {
                    background-color:$color-5; 
                }
                &:before {
                    background-color:$color-3;  
                } 
            }
        }
    
        &_blue {
           
            .button_text {
                color: #383838; 
            }
            &:before {
                background-color: $blue-2;  
            }
            .hyphen {
                background-color:#383838; 
            }
            &:hover {
                &:before {
                    background-color:$color-blue;  
                } 
                .hyphen {
                    background-color:$color-5; 
                }
                .button_text {
                    color: $color-5; 
                }
            }
        }
    
        &_blue_light {
           
            .button_text {
                color: $color-5; 
            }
            &:before {
                background-color: $blue-2;  
            }
            .hyphen {
                background-color:$color-5; 
            }
            &:hover {
                &:before {
                    background-color:$color-blue;  
                } 
                .hyphen {
                    background-color:$color-5; 
                }
                .button_text {
                    color: $color-5; 
                }
            }
        }
    
       
    }
    
    @keyframes kMPRhr {
        0% {
            -webkit-transform: translateX(-1px);
            -ms-transform: translateX(-1px);
            transform: translateX(-1px);
        }
    
        50% {
            -webkit-transform: translateX(5px);
            -ms-transform: translateX(5px);
            transform: translateX(5px);
        }
    
        100% {
            -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
    }
    
}